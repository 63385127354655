import React from 'react'
import {MdArrowForward} from 'react-icons/md'
import Container from '@packages/justo-parts/lib/components/Container'
import Link from 'next/link'

import styles from './styles.module.css'

export default function Links(props) {
  const linkContent = link => (
    <span className={styles.link}>
      <span className={styles.linkText}>{link.linkText}</span>
      <MdArrowForward size={20} />
    </span>
  )
  const className =
    {
      1: 'col-sm-12',
      2: 'col-sm-6',
      3: 'col-sm-4',
      4: 'col-sm-3'
    }[props.links.length] || 'col-sm-4'
  return (
    <div className={styles.container}>
      <Container>
        <div className="row">
          {props.links.map((link, index) => (
            <div key={index} className={'col-xs-12 ' + className}>
              <div className={styles.item}>
                <div className={styles.title}>{link.title}</div>
                <div className={styles.text}>{link.text}</div>
                {link.path.startsWith('/') ? (
                  <Link href={link.path} legacyBehavior>{linkContent(link)}</Link>
                ) : (
                  <a href={link.path} target="blank">
                    {linkContent(link)}
                  </a>
                )}
              </div>
            </div>
          ))}
        </div>
      </Container>
    </div>
  );
}
